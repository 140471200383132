// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _103020230000110320230000Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_103020230000110320230000() {
  return React.useContext(_103020230000110320230000Context);
}

export default _103020230000110320230000Context;
/* prettier-ignore-end */
