// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _01012024Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_01012024() {
  return React.useContext(_01012024Context);
}

export default _01012024Context;
/* prettier-ignore-end */
