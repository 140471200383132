// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _010520230000011220231500Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_010520230000011220231500() {
  return React.useContext(_010520230000011220231500Context);
}

export default _010520230000011220231500Context;
/* prettier-ignore-end */
