// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _1026202310272023Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_1026202310272023() {
  return React.useContext(_1026202310272023Context);
}

export default _1026202310272023Context;
/* prettier-ignore-end */
